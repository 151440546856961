<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">Salary Details</h1>
                    </div><!-- /.col --> 
                </div><!-- /.row --> 
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="collectionTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <td colspan="5">
                                                        <label><p>Total Rider : {{ listData.total_rider }} </p></label>, 
                                                        <label><p>Total Salary : {{ listData.final_amount }}</p></label>
                                                         <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                                                    </td>
                                                    <td>
                                                        <button class="btn btn-warning float-right btn-sm" @click="pdfDownload"> PDF</button>
                                                        <button v-if="listData.status === 1" class="btn btn-success float-right btn-sm mr-2" @click="paid"> Paid</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Total Order</th>
                                                    <th>Advacned Amount</th>
                                                    <th>Salary</th>
                                                    <th>Final Amount</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Rider Name</th>
                                                    <th>Rider Mobile</th>
                                                    <th>Total Order</th>
                                                    <th>Advacned Amount</th>
                                                    <th>Salary</th>
                                                    <th>Final Amount</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in listData.salaries" :key="index">
                                                    <td>{{ item.rider_name }} </td>
                                                    <td>{{ item.rider_mobile }} </td>
                                                    <td>{{ item.total_order }} </td>
                                                    <td>{{ item.salary_advanced }}</td>
                                                    <td>{{ item.salary }}</td>
                                                    <td>{{ item.final_amount }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    import ExportSalarySheet from './SalarySheet'
    
    export default {
        name:'MonthlySalaryCreate',
        data(){
            return {   
                loader: true,   
                listData: [],
            }
        },
        created(){
            this.loadData();
        },
        computed : {
            riderList: function () {
                return this.$store.state.commonObj.riderList
            }
        },
        methods:{
            async paid () {
                this.loader = true
                const params = { id: this.$route.query.id }
                const response = await config.postData('/rider-monthly-salary/paid', params)
                this.loader = false
                if (response.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: 'Salary Paid',
                        color: '#218838'
                    }) 
                    this.$router.push('rider-monthly-salary')
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })
                }
            },
            searchData () {
                this.loadData()
            },
            async loadData(){     
                this.loader = true  
                const id = this.$route.query.id
                const response = await config.getData(`/rider-monthly-salary/details/${id}`)
                this.loader = false
                if (response.success){
                    this.listData = this.getRelationalData(response.data)
                } else {
                    this.listData = []
                } 
            },
            getRelationalData (data) {
                const finalData = data.salaries.map(item => {
                    const rider = this.riderList.find(tmp => tmp.id == item.rider_id)
                    const riderObj = { rider_name: rider.text, rider_mobile: rider.mobile }
                    return Object.assign(item, riderObj)
                })
                const month = this.$store.state.monthList.find(tmp => tmp.id == data.month)
                const monthObj = { month_name: month.text }
                return Object.assign(data, finalData, monthObj)
            },
            pdfDownload() {
                // this.loader = true
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportSalarySheet.returnSalarySheet(base64Logo, this.listData)
                // this.loader = false
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            }
        }
    }
</script>